'use client'

import { Box, Flex } from 'theme-ui'
import { useEffect } from 'react'
import {
  Button,
  AddressLookup,
  validateAddress,
  useModalContext,
  ADDRESS_INPUT_ERROR,
  ModalView,
  ModalErrorView,
  useMyReportsContext,
} from '@propertylens/ui-kit'
import { emitTrackingEvent } from '@propertylens/app-commons'

import { useCheckout } from '~/context/checkout'

const MapSearch = ({
  defaultAddress = 'ex. 123 Main Street, Boston, MA 01234',
  buttonLabel = 'Get PropertyLens Report',
  inputLabel = 'Search by address',
  redirectUrl,
  isSamePageCheckout,
  isColumnLayout,
}) => {
  const {
    selectedPlace,
    setModalView,
    autocompleteInputValue,
    setModalErrorView,
    setAddressInputError,
    setAddressInputErrorType,
    openGetReports,
    setShouldBePopulated,
    existingReport,
    setIsAllowed,
    setWarning,
    isAllowed,
    warning,
    setAddressLookupRedirectURL,
  } = useModalContext()
  const { setReport, setAddress } = useCheckout()
  const googleAPIKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
  useEffect(() => {
    if (!isSamePageCheckout) {
      setAddress(null)
      setReport(null)
    }
  }, [isSamePageCheckout, setAddress, setReport])

  useEffect(() => {
    if (redirectUrl) {
      setAddressLookupRedirectURL(redirectUrl)
    }
  }, [redirectUrl, setAddressLookupRedirectURL])

  const { getReports } = useMyReportsContext()
  useEffect(() => {
    getReports()
  }, [])

  const handleGetReport = async () => {
    try {
      emitTrackingEvent('Address Searched', {
        address: {
          ...selectedPlace,
        },
        component: 'MapSearch',
      })

      if (!isAllowed || (warning && warning.message !== null)) {
        setShouldBePopulated(true)
        openGetReports()
        return
      }

      if (!autocompleteInputValue) {
        emitTrackingEvent('Address Error', {
          address: {
            ...selectedPlace,
          },
          reason: 'INVALID_ADDRESS',
          validationResult: null,
        })
        // open empty address1 field without an error
        setAddressInputError(false)
        openGetReports()
        return
      }

      const validateAddressResult = await validateAddress(
        selectedPlace,
        googleAPIKey
      )

      if (!validateAddressResult) {
        // this will be shown in base form modal
        // and trigger modal
        emitTrackingEvent('Address Error', {
          address: {
            ...selectedPlace,
          },
          reason: 'UNABLE_TO_VALIDATE_ADDRESS',
          validationResult: null,
        })
        setAddressInputError(true)
        setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS)
        openGetReports()
        return
      }

      if (
        !validateAddressResult.verdict?.addressComplete &&
        !validateAddressResult.metadata
      ) {
        emitTrackingEvent('Address Error', {
          address: {
            ...selectedPlace,
          },
          reason: 'INVALID_ADDRESS',
          validationResult: validateAddressResult?.verdict,
        })
        setAddressInputError(true)
        setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS)
        openGetReports()
        return
      }

      if (existingReport) {
        setShouldBePopulated(true)
        openGetReports()
        return
      }

      if (
        validateAddressResult.metadata &&
        validateAddressResult.metadata.business
      ) {
        emitTrackingEvent('Commercial Address', {
          address: { ...selectedPlace },
        })
        setIsAllowed(true)
        setWarning({
          message: (
            <p>
              This looks like a commercial property, which may have limited
              information, are you sure you want to proceed?
            </p>
          ),
          type: 'commercialAddress',
        })
        setShouldBePopulated(true)
        openGetReports()
        return
      }

      const missingComponentTypes =
        validateAddressResult?.address?.missingComponentTypes

      if (missingComponentTypes && missingComponentTypes.length > 0) {
        if (missingComponentTypes.includes('subpremise')) {
          // and trigger modal
          setModalView(ModalView.SecondaryForm)
          openGetReports()
        } else {
          setAddressInputError(true)
          setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS)
          openGetReports()
        }
      } else {
        // and trigger modal
        setModalView(ModalView.Preview)
        openGetReports()
      }
    } catch (error) {
      // and trigger modal
      setModalView(ModalView.Error)
      setModalErrorView(ModalErrorView.ErrorOnBase)
      openGetReports()
    }
  }

  if (!redirectUrl) {
    return <h1>MapSearch: redirectUrl prop is required</h1>
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <AddressLookup
        parentComponent="MapSearch"
        googleAPIKey={googleAPIKey}
        inputLabel={inputLabel}
        placeholder={defaultAddress}
        classNames={{
          input: isColumnLayout
            ? 'mt-9 font-[raleway]'
            : 'md:text-3xl mt-9 font-[raleway]',
          inputWrapper: 'px-7 pb-5 bg-white h-full mb-4 font-[raleway]',
        }}
      />
      <Flex
        sx={{
          bottom: 0,
          flexDirection: isColumnLayout ? 'row' : [null, null, 'column'],
          justifyContent: 'center',
          left: [0, null, 'auto'],
          position: isColumnLayout
            ? 'relative'
            : ['relative', 'relative', 'absolute'],
          right: isColumnLayout ? 'auto' : [0, null, 4],
          top: [null, null, 0],
        }}
      >
        <Button
          radius="sm"
          type="button"
          color="primary"
          size="lg"
          onClick={handleGetReport}
          data-attribute="gtm-checkout-address-search"
        >
          {buttonLabel}
        </Button>
      </Flex>
    </Box>
  )
}

export default MapSearch
